import React from "react";
import { Route, Routes } from 'react-router-dom';
import Overview from "../pages/overview/Overview";
import Blog from "../pages/blog/Blog";

const RouterApp = () => {
    return (
        <Routes>
            <Route path="/" element={<Overview />} />
            <Route path="/blogs/:id" element={<Blog />} />
        </Routes>
    )
}

export default RouterApp;
