import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import "./Blog.css"

const Blog = () => {
    const [blog, setBlog] = useState([])
    const { id } = useParams();

    console.log("Test: ", id)
    
    useEffect(() =>{
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `https://w3c-service.netlify.app/.netlify/functions/api/blog/${id}`,
            headers: { }
          };
          
          axios.request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            setBlog(response.data.results)
          })
          .catch((error) => {
            // console.log(error);
          });
    }, [])
    
    return (
        <div className="blog-detail">
            {
                blog.map((item: any, index: number) => {
                    if(item.type==="image")
                        return <img className="blog-detail-image" src={item.image.file.url} alt=""/>
                    if(item.type==="paragraph")
                        return (
                            <div className="blog-detail-para">
                                {
                                    item.paragraph.rich_text.map((name: any) => {
                                        return name.text.content
                                    }).join(" ")
                                }
                            </div>
                        )
                    return <></>
                })
            }
        </div>
    )
}

export default Blog;