import React, { useEffect } from "react"
import { Link } from "react-router-dom"

import "./Portfolio.css"

import bitcoin from "../../assets/portfolio/1.svg"
import ethereum from "../../assets/portfolio/2.svg"
import opensea from "../../assets/portfolio/4.png"
import theGraph from "../../assets/portfolio/8.jpg"
import uniswap from "../../assets/portfolio/9.png"
import magicEden from "../../assets/portfolio/11.png"

import aave from "../../assets/portfolio/aave.png"
import aptos from "../../assets/portfolio/aptos.jpg"
import arb from "../../assets/portfolio/arb.png"
import binance from "../../assets/portfolio/binance.svg"
import dydx from "../../assets/portfolio/dydx.png"
import fil from "../../assets/portfolio/fil.svg"
import flow from "../../assets/portfolio/flow.png"
import link from "../../assets/portfolio/link.png"
import matic from "../../assets/portfolio/matic.svg"
import metamask from "../../assets/portfolio/metamask.png"
import mkr from "../../assets/portfolio/mkr.png"
import sandbox from "../../assets/portfolio/sandbox.png"
import solana from "../../assets/portfolio/solana.svg"
import trust from "../../assets/portfolio/trust.png"

const projects = [
    {
        img: bitcoin,
        website: "http://bitcoin.org"
    },
    {
        img: ethereum,
        website: "https://ethereum.org/en/"
    },
    {
        img: opensea,
        website: "https://opensea.io/about"
    },
    {
        img: theGraph,
        website: "https://thegraph.com/"
    },
    {
        img: uniswap,
        website: "https://uniswap.org/"
    },
    {
        img: magicEden,
        website: "https://magiceden.io/"
    },
    {
        img: aave,
        website: "https://aave.com/"
    },
    {
        img: aptos,
        website: "https://aptoslabs.com/"
    },
    {
        img: arb,
        website: "https://arbitrum.io/"
    },
    {
        img: binance,
        website: "https://www.bnbchain.org/en"
    },
    {
        img: dydx,
        website: "https://dydx.exchange/"
    },
    {
        img: fil,
        website: "https://filecoin.io/"
    },

    {
        img: flow,
        website: "https://flow.com/"
    },
    {
        img: link,
        website: "https://chain.link/"
    },
    {
        img: matic,
        website: "https://polygon.technology/"
    },
    {
        img: metamask,
        website: "https://metamask.io/"
    },
    {
        img: mkr,
        website: "https://makerdao.com/en/"
    },
    {
        img: sandbox,
        website: "https://www.sandbox.game/en/"
    },

    {
        img: solana,
        website: "https://solana.com/"
    },
    {
        img: trust,
        website: "https://trustwallet.com/"
    },
]

const Portfolio = () => {

    useEffect(() => {

    }, [])

    return (
        <div className="portfolio">
            <div className="portfolio-title">
                Portfolio
            </div>
            <div className="portfolio-grid">
                {
                    projects.map((item: any, index: number) =>{
                        return (
                            <Link key={ index } to={ `${item.website}` } target="_blank">
                                <div className="portfolio-project">
                                    <img src={ item.img } alt="" className="portfolio-project-image"/>
                                    {/* <div className="portfolio-project-name">
                                        { item.name }
                                    </div>
                                    <div className="portfolio-project-description">
                                        { item.description }
                                    </div> */}
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Portfolio;