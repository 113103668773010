import React, { useEffect, useState } from "react"

import "./Overview.css"
import Stories from "../stories/Stories"
import Blogs from "../blogs/Blogs"
import Portfolio from "../portfolio/Portfolio"

const Overview = () => {

    const [show, setShow] = useState(false)
    const [showLetter, setShowLetter] = useState(0) 
    const [positionType, setPositionType] = useState(0)
    const [scaleRatio, setScaleRatio] = useState(1)

    let lastPosition = 0

    useEffect(() => {
        setShow(true)
        const interval = setInterval(() => {
            const current = parseInt(localStorage.getItem("showLetter") || "0")
            localStorage.setItem("showLetter", (current + 1).toString())
            setShowLetter(current + 1)
        }, 1000);

        // 
        window.addEventListener('scroll', handleScroll)
        return () => {
            clearInterval(interval);
            window.removeEventListener('scroll', handleScroll)
        }
    }, []); 

    const handleScroll = () =>{
        const orginalElement = document.getElementById("overview-component-1") as HTMLElement;
        const finalElement = document.getElementById("overview-component-1-final") as HTMLElement;

        const orginalRect = orginalElement.getBoundingClientRect()
        const finalRect = finalElement.getBoundingClientRect()

        getPositionType(orginalRect)
        setScaleRatio(getZoomRatio(finalRect))
    }

    const getPositionType = (orginalRect: any) =>{
         if(orginalRect.y < -25)
            setPositionType(0)
        if(lastPosition > document.documentElement.scrollTop){
            setPositionType(0)
        }
        else if(orginalRect.y > -25 && orginalRect.y < 100)
            setPositionType(1)
        lastPosition = document.documentElement.scrollTop
    }

    const getZoomRatio = (finalPosition: any) => {
        const orginalElement = document.getElementById("overview-component-1") as HTMLElement;
        const finalElement = document.getElementById("overview-component-1-final") as HTMLElement;
        if(finalPosition.y < 0){
            orginalElement.style.display = "none"
            finalElement.style.opacity  = "1"
            finalElement.style.visibility = "visible"
            return 2
        }else{
            orginalElement.style.display = "grid"
            finalElement.style.opacity  = "0"
            finalElement.style.visibility = "hidden"
        }
        return 1 + ((window.innerHeight - finalPosition.y)/window.innerHeight < 0 ? 0 : (window.innerHeight - finalPosition.y)/window.innerHeight)
    }

    const getStyleScaleDiV = () =>{
        const style = {transform: `scale(${scaleRatio*100}%)`}
        return style
    }

    return (
        <div className="overview">
            <div className="overview-wrapper" id="overview-wrapper">
                <div className="overview-slogan-description">
                    <div>
                        We invest in Web3 projects
                    </div>
                    <div>
                        to create more economic
                    </div>
                    <div>
                        freedom for the community
                    </div>
                    <div className="overview-wrapper-opacity" style={ show? {transform: "translateX(100%)"} : {}}/>
                </div>
                <div className="overview-scroll-down">
                    SCROLL DOWN
                </div>
                <div className="overview-animation-brand">
                    {
                        Array(8*9).fill(0).map((item: any, index: number) =>{
                            return (
                                <div key={index} className="overview-animation-brand-item" style={index < 5 ? {background: "#000"} : {}}>
                                </div>
                            )
                        })
                    }
                    <div className="overview-animation-brand-item-w">
                        <div className="overview-animation-brand-item-w-1" style={showLetter%3 === 0 ? {transform: "translateY(-1%)"} : {}}/>
                        <div className="overview-animation-brand-item-w-2" style={showLetter%3 === 0 ? {transform: "translateY(-1%)"} : {}}/>
                        <div className="overview-animation-brand-item-w-3" style={showLetter%3 === 0 ? {transform: "translateY(1%)"} : {}}/>
                    </div>
                    <div className="overview-animation-brand-item-c">
                        <div className="overview-animation-brand-item-c-1" style={showLetter%3 === 2 ? {transform: "translateX(1%)"} : {}}/>
                    </div>
                    <div className="overview-animation-brand-item-3">
                        <div className="overview-animation-brand-item-3-1" style={showLetter%3 === 1 ? {transform: "translateX(-1%)"} : {}}/>
                    </div>
                </div>
            </div>
            <div className="overview-component-1" id="overview-component-1" style={positionType === 1 ? {position: "fixed", width: "100vw", height: "100vh", zIndex: 3, top: "0px"} : {}}>
                <div className="overview-component-1-grid">
                    <div className="overview-component-1-grid-blur"> W </div>
                    <div className="overview-component-1-grid-litter">
                        W
                    </div>
                </div>

                <div className="overview-component-2-grid">
                    <div className="overview-component-1-grid-blur"> 3 </div>
                    <div className="overview-component-1-grid-litter">
                        3
                    </div>
                </div>

                <div className="overview-component-3-grid">
                    <div className="overview-component-1-grid-blur"/>
                        <div className="overview-component-1-grid-litter"/>
                    </div>
                <div className="overview-component-4-grid">
                    <div className="overview-component-1-grid-blur"> C </div>
                    <div className="overview-component-1-grid-litter">
                        C
                    </div>
                </div>
                <div className="overview-component-1-slogan" id="overview-component-1-slogan" style={getStyleScaleDiV()} >
                    <div className="overview-component-1-slogan-detail">
                        <div className="overview-component-1-slogan-detail-1">
                            A financial backing
                        </div>
                        <div className="overview-component-1-slogan-detail-1">
                            & development support
                        </div>
                    </div>
                </div>
            </div>
            <div className="overview-component-1-final" id="overview-component-1-final" style={positionType === 1 ? {marginTop: scaleRatio > 1.999 ? "0px" : "100vh", opacity: 0, visibility: "hidden"} : {}}>
                <div className="overview-component-1-slogan-detail-final">
                    <div className="overview-component-1-slogan-detail-1-final">
                        A financial backing
                    </div>
                    <div className="overview-component-1-slogan-detail-1-final">
                        & development support
                    </div>
                </div>
            </div>
            <div className="overview-key-2">
                <div className="overview-key-2-circle"/>
                <div className="overview-key-2-line-1">
                    Sharing knowledge to building
                </div>
                <div className="overview-key-2-line-2">
                    a strong community
                </div>
                <div className="overview-key-2-line-3">
                    & legal  compliance advice
                </div>
            </div>
            <Portfolio />
            <Stories />
            <Blogs />
        </div>
    )
}

export default Overview;