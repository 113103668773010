import React, { useEffect, useState } from "react";

import axios from "axios"

import "./Blogs.css"
import { Link } from "react-router-dom";

import wCircle from "../../assets/w-circle.svg"
import arrow from "../../assets/arrow.svg"

interface BlogInfo{
    id: string
}

const Blogs = () =>{

    const [blogIndex, setBlogIndex] = useState(0)

    const [blogs, setBlogs] = useState({
        results: []
    })

    useEffect(() => {
        let config = {
            url: "https://w3c-service.netlify.app/.netlify/functions/api/blogs",
            method: 'get',
            maxBodyLength: Infinity,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Content-Type",
                "Access-Control-Allow-Methods": "GET, POST, OPTION",
            },
        };
          
        axios.request(config)
        .then((response) => {
            // console.log(JSON.stringify(response.data));
            setBlogs(response.data)
        })
        .catch((error) => {
        console.log(error);
        });

    }, [])

    const navBlogIndex = (mode: boolean) => {
        if(mode===true){
            if(blogIndex===blogs.results.length-1)
                return
            (document.getElementById((blogs.results[blogIndex+1] as BlogInfo).id) as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            setBlogIndex(blogIndex+1)
        }
        if(mode === false){
            if(blogIndex===0)
                return
            (document.getElementById((blogs.results[blogIndex-1] as BlogInfo).id) as HTMLElement).scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            setBlogIndex(blogIndex-1)
        }
    }

    return (
        <div className="blogs">
            <div className="blogs-title">
                READ THE LATEST
                <div className="blogs-view-all">
                    View all
                </div>
            </div>
            <div className="blogs-grid-wrapper">
                <div className="blogs-grid">
                    {
                        blogs.results.map((item: any, index: number) => {
                            
                            return (
                                <Link to={`/blogs/${item.id}`} id={item.id}>
                                    {
                                        <div className="blog" key={index}>
                                            <div className="blog-image-wrapper">
                                                <img className="blog-image" src={item.properties.Cover.files[0].file.url} alt=""/>
                                            </div>
                                            <div className="blog-item-title">
                                                {
                                                    item.properties.Name.title.map((name: any) => {
                                                        return name.plain_text
                                                    }).join(" ")
                                                }
                                            </div>
                                            <div className="blog-item-time">
                                                {
                                                    item.properties.Date.date.start.replaceAll("-", ".")
                                                }
                                                {/* <img src={wCircle} alt=""/> */}
                                            </div>
                                        </div>
                                    }
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
            <div className="blogs-nav">
                <img src={arrow}  alt="" className="blogs-nav-button" onClick={() => navBlogIndex(false)}/>
                <img src={arrow} style={{transform: "rotate(180deg)"}} alt="" className="blogs-nav-button" onClick={() => navBlogIndex(true)}/>
            </div>
        </div>
    )
}

export default Blogs;