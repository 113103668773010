import React from 'react';
import { BrowserRouter} from 'react-router-dom';

import './App.css';

import RouterApp from './routes/Router';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';

function App() {
  return (
    <BrowserRouter>
      <div className="app">
        <Header />
        <RouterApp />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
