import React from "react";

import "./Stories.css"

import logo from "../../assets/logo.png"

const Stories = () => {
    return (
        <div className="stories">
            <div className="stories-slogan">
                Dedicated to contributing to the creation of a decentralized and global financial future.
            </div>
            <div className="stories-details">
                <div className="stories-image-wrapper">
                    <img src={logo} alt="" className="stories-image"/>
                </div>
                <div className="stories-details-info">
                    <div className="stories-details-info-1">
                        We endorse outstanding entrepreneurs dedicated to propelling the development of the crypto/Web3 ecosystem while aligning with W3C’s overarching goal of fostering increased economic freedom globally.
                    </div>
                    <div className="stories-details-info-1">
                        We form partnerships with founding teams during the earliest phases of development and maintain a comprehensive investment strategy encompassing all segments within the crypto economy.
                    </div>
                    <div className="stories-details-info-1">
                        Our portfolio includes investments in numerous teams engaged in the creation of layer 1 protocols, Web3 infrastructure, centralized on-ramps, decentralized finance, NFTs, metaverse technologies, developer tooling, and various other sectors.
                    </div>
                    <div className="stories-details-info-1">
                        Our commitment extends beyond financial backing. We provide support to founders through leveraging operational expertise, facilitating distribution, establishing strategic partnerships, and more.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Stories;