import React from "react";

import "./Footer.css"

import footerImage from "../../assets/footer-image.svg"

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-content">
                <img className="footer-image" src={footerImage} alt=""/>
                <div className="footer-info">
                    <div className="footer-slogan">
                        All rights reserved
                    </div>
                    <div className="footer-socials">
                        <a className="footer-x" rel="noreferer" href="https://twitter.com/W3CVentures" target="_blank">
                            X/Twitter
                        </a>
                        <a className="footer-discord" rel="noreferer" href="https://twitter.com/W3CVentures" target="_blank">
                            Discord
                        </a>
                    </div>
                </div>
            </div>
            <div className="footer-bg"/>
        </div>
    )
}

export default Footer;