import React, { useState } from "react"
import { Link } from "react-router-dom"

import "./Header.css"

import logo from "../../assets/logo.png"
import menu from "../../assets/menu.svg"

const navs = [
    {
        name: "OVERVIEW",
        link: "/"
    },
    {
        name:"PORTFOLIO",
        // link: "/portfolio",
        link: "/"
    },
    {
        name: "STORIES",
        // link: "/stories",
        link: "/"
    },
    {
        name: "BLOGS",
        // link: "/blogs"
        link: "/"
    }
]

const Header = () =>{
    const [openMenu, setOpenMenu] = useState(false)


    return (
        <div className="header">
            <Link to="/" className="header-image-project-name">
                <div className="header-image-project-name">
                    <img src={logo} alt="" className="header-image"/>
                    {/* <div className="header-project-name">
                        W3C Ventures
                    </div> */}
                </div>
            </Link>
            <div className="header-menu" onClick={() => setOpenMenu(!openMenu)}>
                <img className="" src={menu} alt=""/>
            </div>
            <div className="header-navigation" style={openMenu ? {opacity: 1, visibility: "visible"} : {}}>
                {
                    navs.map((item: any, index: number) =>{
                        return (
                            <Link key={index} to={item.link}>
                                <div className="header-navigation-item">
                                    { item.name }
                                </div>
                            </Link>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Header;